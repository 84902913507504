import { DisplayEntity, Entity } from '@core/enums/entity.enum';
import { ListOptions } from '@capturum/api';

export interface EntityConfig {
  // Identifier value used for editing & copy pages
  id?: string;
  // Default entity name
  name: Entity;
  // Overwrite entity.name if BE and FE do not match, e.g. order(BE) vs sales(FE)
  displayName?: Entity | DisplayEntity;
  // Overwrite permissions. E.g. there is no permission: dxp.product-group.manage, because it will use dxp.product.manage
  permissionGroup?: Entity | DisplayEntity;
  // Complete overview pages rely on a routePath
  routePath?: string | string[];
  // Override default API options here
  apiOptions?: ListOptions;
}

/**
 * Use this method to extract the name of the entity for url's.
 * E.g. When editing an order, don't use: 'order/:id' as the url, but: 'sales/:id'
 *
 * @param entity
 */
export function getUserFriendlyName(config: EntityConfig): string {
  return config.displayName ? config.displayName : config.name;
}

/**
 * Use this method always check the name of permission in case it uses the same permission of a parent entity.
 * E.g. There is no permission for product-group, but it uses the same permissions as product.
 *
 * @param entity
 */
export function getPermissionGroup(config: EntityConfig): string {
  return config.permissionGroup ? config.permissionGroup : config.name;
}
